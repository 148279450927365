<template>
  <div class="container-fluid py-5 bg-white">
    <div class="row">
      <div class="container py-5">
        <div class="row">
          <div class="container text-center">
            <img src="../assets/images/page-not-found.png"
            style="width: 250px"/>
            <div class="w-100 py-4 clearfix">
              <h3>Page not found</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: "PageNotFound"
}
</script>

<style scoped>

</style>